'use strict';

angular.module('cpformplastApp.deliveries')
  .controller('DeliveryCreationController', function($scope, Modal, DataManager, $state, Notification) {

    $scope.jobs = [];
    $scope.delivery = {
      subdeliveries: [],
      timeOfDay: 'am'
    };
    $scope.submitted = false;
    $scope.subdeliverySubmitted = false;

    // Merchandise selection
    $scope.minDate = new Date();
    $scope.subdelivery = {};

    // Incomplete box
    $scope.incompleteBox = {
      enabled: false,
      quantity: 0,
    };
    
    $scope.init = function() {

      DataManager.fetchJobs().then((data) => {
        $scope.jobs = data;
      })
      .catch(err => {
        console.log(err);
        Notification.error('Un probème est survenu lors du chargement des jobs');
      });

      if ($state.params.deliveryId) {
        DataManager.fetchDelivery($state.params.deliveryId).then(function(delivery){
          $scope.delivery = delivery;
          $scope.delivery.date = new Date(delivery.date);
        },function(error){
          console.log(error);
        });
      } else {
        DataManager.fetchLatestDelivery().then(function(latestDelivery){
          if (latestDelivery != null) {
            $scope.delivery.bol = (parseInt(latestDelivery.bol) + 1).toString();
          }
        }, function(error){
          console.log(error);
        })
      }

    };

    $scope.selectTimeOfDay = function(timeOfDay) {
      $scope.delivery.timeOfDay = timeOfDay;
    };

    $scope.submitSubdelivery = function(form) {
      $scope.subdeliverySubmitted = true;
      if (!form.$valid || !$scope.selectedJob._id) {
        return false;
      }

      // merge boxQuantity with incomplete box
      let boxQuantity = $scope.subdelivery.boxQuantity;
      if ($scope.incompleteBox.quantity > 0 && $scope.selectedJob != null && $scope.selectedJob.item != null && $scope.selectedJob.item.quantityPerBox != null) {
        boxQuantity += $scope.incompleteBox.quantity / $scope.selectedJob.item.quantityPerBox;
      }

      $scope.subdelivery.job = $scope.selectedJob;
      $scope.delivery.subdeliveries.push({
        'job': $scope.subdelivery.job,
        'jobId': $scope.subdelivery.job._id,
        'quantity': Number($scope.subdelivery.quantity).toLocaleString(),
        'boxQuantity': boxQuantity,
        'clientName': $scope.subdelivery.clientName,
        'clientAddress': $scope.subdelivery.clientAddress,
        'transport': $scope.subdelivery.transport,
        'bol': $scope.delivery.bol
      });
      $scope.subdeliverySubmitted = false;
      $scope.subdelivery = {};
      $scope.selectedJob = undefined;

      $scope.incompleteBox.enabled = false;
    };

    $scope.submitDelivery = function(form, create) {
      $scope.submitted = true;

      if (form.shipper.$invalid || form.date.$error.valid || form.date.$error.required) {
        return false;
      }

      var noSub = true;
      for (var i=0 ; i<$scope.delivery.subdeliveries.length ; i++) {
        if (!$scope.delivery.subdeliveries[i].toDelete) {
          noSub = false;
        }
      }
      if ($scope.delivery.subdeliveries <= 0 || noSub) {
        Notification.error('Il faut associé au moins une marchandise à la livraison');
        return false;
      }

      for (var i=0 ; i<$scope.delivery.subdeliveries.length ; i++) {
        if(!$scope.delivery.subdeliveries[i].quantity) {
          Notification.error('Veuillez specifier les quantités!');
          return false;
        }
      }

      var promise ;
      if (!$scope.delivery._id) {
        promise = DataManager.createDelivery($scope.delivery);
      } else {
        promise = DataManager.updateDelivery($scope.delivery);
      }

      promise.then((data) => {
        $scope.delivery._id = data._id;
        $state.params.deliveryId = data._id;

        for (var i=0 ; i<$scope.delivery.subdeliveries.length ; i++) {
          $scope.delivery.subdeliveries[i].deliveryId = $scope.delivery._id;
          $scope.saveSubdelivery($scope.delivery.subdeliveries[i], create);
        }
      })
      .catch(err => {
        console.log(err);
        Notification.error('Un problème est survenu');
      });

    };

    $scope.saveSubdelivery = function(subdelivery, create) {
      subdelivery.saved = false;
      subdelivery.deleted = false;

      var promise ;
      if (!subdelivery._id && !subdelivery.toDelete) {
        promise = DataManager.createSubdelivery(subdelivery);
      } else if (subdelivery._id && !subdelivery.toDelete) {
        promise = DataManager.updateSubdelivery(subdelivery);
      } else if (subdelivery._id && subdelivery.toDelete) {
        promise = DataManager.deleteSubdelivery(subdelivery);
      } else {
        subdelivery.deleted = true;
        $scope.detectSaveCompletion(create);
        return;
      }

      promise.then((data) => {
        if (data) {
          subdelivery._id = data._id;
          subdelivery.saved = true;
        } else {
          subdelivery.deleted = true;
        }
        $scope.detectSaveCompletion(create);
      })
      .catch(err => {
        console.log(err);
        Notification.error('Un problème est survenu');
      });

    };

    $scope.detectSaveCompletion = function(create) {
      var updatedSubdeliveries = [];
      for (var i=0 ; i<$scope.delivery.subdeliveries.length ; i++) {
        if (!$scope.delivery.subdeliveries[i].saved && !$scope.delivery.subdeliveries[i].deleted) {
          return false;
        }

        if (!$scope.delivery.subdeliveries[i].deleted) {
          updatedSubdeliveries.push($scope.delivery.subdeliveries[i]);
        }
      }
      Notification.success('La livraison fut enregistrée');
      if(create){
        $state.go('deliveries/delivery-management/delivery-creation', {'deliveryId': ''}, {reload:true});
      } else {
        $state.go('deliveries/delivery-management/delivery-creation', {'deliveryId' : $scope.delivery._id}, {reload:true});
      }
      $scope.delivery.subdeliveries = updatedSubdeliveries;
    };

    $scope.delete = Modal.confirm.delete(res => {
      $scope.deleteDelivery();
    });

    $scope.deleteDelivery = function() {
      DataManager.deleteDelivery($scope.delivery).then((data) => {
        Notification.success("La livraison fut supprimée avec succès");
        $state.go('deliveries/delivery-management');
      })
      .catch(err => {
        console.log(err);
        Notification.error("La livraison n'a pas pu être supprimé");
      });
    };

    $scope.$on('$stateChangeStart',
      function(event, next, current){
        if ($scope.form.$dirty && !$scope.submitted) {
          if(!confirm("Souhaitez vous quitter cette page? Vous perderez alors tout contenue non sauvegarder.")) {
           event.preventDefault();
          }
        }
      });

    $scope.$watch('selectedJob._id', function() {
      if ($scope.selectedJob && $scope.selectedJob._id) {
        $scope.subdelivery.clientName = $scope.selectedJob.item.client.name;
        $scope.subdelivery.clientAddress = $scope.selectedJob.item.client.shippingAddress;
        $scope.subdelivery.transport = $scope.selectedJob.transport;
      } else {
        $scope.subdelivery.clientName = "";
        $scope.subdelivery.clientAddress = "";
        $scope.subdelivery.transport = "";
      }
    });

    $scope.$watch('subdelivery.boxQuantity', function() {
      if ($scope.subdelivery.boxQuantity !== null) {
        $scope.subdelivery.boxQuantity = Math.ceil($scope.subdelivery.boxQuantity);
      }
    });

    $scope.$watch('incompleteBox.enabled', function () {
      if (!$scope.incompleteBox.enabled) {
        $scope.incompleteBox.quantity = 0;
        $scope.updateSkidQuantity();
      }
    });
    
    $scope.$watch('incompleteBox.quantity', function() {
      if ($scope.incompleteBox.quantity === undefined) {
        $scope.incompleteBox.quantity = 0;
      } else if ($scope.selectedJob != null && $scope.selectedJob.item != null && $scope.selectedJob.item.quantityPerBox != null) {
        const quantityPerBox = $scope.selectedJob.item.quantityPerBox;
        if ($scope.incompleteBox.quantity > quantityPerBox) {
          $scope.incompleteBox.quantity = quantityPerBox;
        }
      }
      
      if ($scope.incompleteBox.quantity != null) {
        $scope.incompleteBox.quantity = Math.ceil($scope.incompleteBox.quantity);
      }
    });

    $scope.updateSkidQuantity = function() {
      if (!$scope.selectedJob) return;
      let boxQuantity = $scope.subdelivery.boxQuantity;
      if ($scope.incompleteBox.quantity > 0) {
        boxQuantity += 1;
      }
      $scope.subdelivery.quantity = Math.ceil(boxQuantity / $scope.selectedJob.item.quantityPerSkid);
    };

    $scope.updateBoxQuantity = function() {
      if (!$scope.selectedJob) return;
      $scope.subdelivery.boxQuantity = Math.ceil($scope.subdelivery.quantity * $scope.selectedJob.item.quantityPerSkid);
    };

    $scope.availableBoxQuantity = function() {
      if ($scope.selectedJob != null && $scope.selectedJob.item != null) {
        return Math.ceil($scope.selectedJob.unitCount / $scope.selectedJob.item.quantityPerBox);
      } else {
        return 0;
      }
    };

    $scope.totalPerBox = function() {
      if ($scope.selectedJob != null && $scope.selectedJob.item != null && $scope.selectedJob.item.quantityPerBox != null) {
        return `/${$scope.selectedJob.item.quantityPerBox}`;
      } else {
        return "";
      }
    }

    $scope.downloadSubdeliverySlipPdf = function(subdeliveryId, bol) {
      DataManager.downloadSubdeliverySlipPdf(subdeliveryId, bol);
    };

    $scope.downloadDeliverySlipPdf = function(deliveryId) {
      DataManager.downloadDeliverySlipPdf(deliveryId);
    };

    $scope.getSubdeliveryBol = function(index) {
      return `${$scope.delivery.bol}-${String.fromCharCode(65 + index)}`;
    };

    $scope.init();
});
